import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const AsyncSelect = (props) => {
  const {
    id,
    options,
    loading,
    value,
    setValue,
    label,
  } = props;
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      id={id}
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      loading={loading}
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, selected) => option.value === selected.value}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AsyncSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  value: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string,
};

AsyncSelect.defaultProps = {
  options: [],
  loading: true,
  value: null,
  label: 'Selecciona una opción',
};

export default AsyncSelect;
