import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';

const PADDING_LEFT_BUTTON = 4;

const CollapsibleListItemButton = ({ selected, title, icon, onClick }) => (
  <ListItemButton
    sx={{
      pl: PADDING_LEFT_BUTTON,
      bgcolor: selected ? 'white' : 'inherit',
    }}
    onClick={onClick}
  >
    <ListItemText primary={title} />
    <ListItemIcon>{icon}</ListItemIcon>
  </ListItemButton>
);

CollapsibleListItemButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CollapsibleListItemButton;
