import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import PropTypes from 'prop-types';

const CheckboxGroup = ({ options }) => (
  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
    <FormLabel component="legend">Adjuntar archivo de cesión</FormLabel>
    <FormGroup>
      {options.map(({ checked, label, handleChange }) => (
        <FormControlLabel
          key={label}
          control={
            <Checkbox checked={checked} onChange={handleChange} name={label} />
              }
          label={label}
        />
      ))}
    </FormGroup>
  </FormControl>
);

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    checked: PropTypes.bool,
    label: PropTypes.string,
    handleChange: PropTypes.func,
  })).isRequired,
};

export default CheckboxGroup;
