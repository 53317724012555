import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatRut } from '../../helpers';

const RestrictionDetails = ({ row }) => {
  const { contractType, contractTypeLabel, endorser, triggeringInvoices, description } = row;

  const hasDetail = contractType
    || (endorser?.relatedPerson?.name)
    || (triggeringInvoices?.edges?.length > 0);

  const hasDescription = description;

  if (!hasDetail && !hasDescription) {
    return (
      <Box sx={{ textAlign: 'center', padding: '16px' }}>
        <Typography variant="body2">
          No hay información adicional
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingLeft: '16px', paddingTop: '8px' }}>
      {hasDetail && (
        <>
          <Typography variant="subtitle1" fontWeight="bold">
            Detalle:
          </Typography>
          {contractType && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginLeft: '16px' }}>
              <Typography variant="body2">
                Tipo de contrato: {contractTypeLabel}
              </Typography>
            </Box>
          )}
          {endorser?.relatedPerson?.name && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginLeft: '16px' }}>
              <Typography variant="body2">
                Aval del contrato: {endorser.relatedPerson.name} |
                &nbsp;{formatRut(endorser.relatedPerson.rut)}
              </Typography>
            </Box>
          )}
          {triggeringInvoices?.edges?.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginLeft: '16px' }}>
              <Typography variant="body2">
                Folios: {triggeringInvoices.edges.map((edge) => edge.node.folio).join(', ')}
              </Typography>
            </Box>
          )}
        </>
      )}
      {hasDescription && (
        <>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ paddingTop: hasDetail ? '8px' : '0px' }}
          >
            Descripción adicional:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', marginLeft: '16px' }}>
            <Typography variant="body2">
              {description || 'Sin descripción'}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

RestrictionDetails.propTypes = {
  row: PropTypes.shape({
    contractType: PropTypes.string,
    contractTypeLabel: PropTypes.string,
    endorser: PropTypes.shape({
      relatedPerson: PropTypes.shape({
        name: PropTypes.string,
        rut: PropTypes.string,
      }),
    }),
    triggeringInvoices: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            folio: PropTypes.string,
          }),
        }),
      ),
    }),
    description: PropTypes.string,
  }).isRequired,
};

export default RestrictionDetails;
