import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CollapsibleListItemButton from './CollapsibleListItemButton';

const CollapsibleList = ({ title, items, startOpen }) => {
  const [selectedItemId, setSelectedItemId] = useState();
  const [openCollapsibleList, setOpenCollapsibleList] = useState(startOpen);

  return (
    <List>
      <ListItemButton
        onClick={() => setOpenCollapsibleList(!openCollapsibleList)}
      >
        <ListItemText primary={title} />
        {openCollapsibleList ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore color="primary" />
        )}
      </ListItemButton>
      <Collapse in={openCollapsibleList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(({ id, title: itemTitle, icon, onClick }) => (
            <CollapsibleListItemButton
              key={id}
              title={itemTitle}
              icon={icon}
              selected={selectedItemId === id}
              onClick={() => {
                setSelectedItemId(id);
                onClick();
              }}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
};

CollapsibleList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  startOpen: PropTypes.bool,
};

CollapsibleList.defaultProps = {
  startOpen: false,
};

export default CollapsibleList;
